import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

export const AuthLogin = createAction(
  '[Auth Page] Login',
  props<{ login: string; password: string }>(),
);
export const AuthLoginSuccess = createAction(
  '[Auth Page] Login Success',
  props<{ twoFa: any; requestID: string }>(),
);
export const AuthLoginError = createAction(
  '[Auth Page] Login Error',
  props<{ error: HttpErrorResponse }>(),
);

export const AuthLoginConfirmation = createAction(
  '[Auth Page] Confirmation',
  props<{ code: string }>(),
);
export const AuthLoginConfirmationSuccess = createAction(
  '[Auth Page] Confirmation Success',
);
export const AuthLoginConfirmationError = createAction(
  '[Auth Page] Confirmation Error',
  props<{ error: HttpErrorResponse }>(),
);
export const AuthLoginConfirmationResend = createAction(
  '[Auth Page] Confirmation Resend',
);

export const AuthLoginErrorReset = createAction('[Auth Page] Error Reset');
export const AuthLoginReset = createAction('[Auth Page] Reset');
