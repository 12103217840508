import { createSelector } from '@ngrx/store';
import { IAuthLoginInitialState } from '@common/interfaces';

export const authLogin = 'authLogin';

const authLoginStore = (state: any) => state[authLogin];

export const selectLogin = createSelector(
  authLoginStore,
  (state: IAuthLoginInitialState) => state,
);

export const selectLoginIsPending = createSelector(
  selectLogin,
  ({ isPending }) => isPending,
);
export const selectLoginError = createSelector(
  selectLogin,
  ({ error }) => error,
);
export const selectLoginTwoFa = createSelector(
  selectLogin,
  ({ twoFa }) => twoFa,
);
export const selectLoginRequestId = createSelector(
  selectLogin,
  ({ requestID }) => requestID,
);
