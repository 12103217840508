import { createReducer, on } from '@ngrx/store';
import { authLogin } from './selectors';
import {
  AuthLogin,
  AuthLoginConfirmation,
  AuthLoginConfirmationError,
  AuthLoginError,
  AuthLoginErrorReset,
  AuthLoginReset,
  AuthLoginSuccess,
} from './actions';
import { IAuthLoginInitialState } from '@common/interfaces';

const authLoginInitialState: IAuthLoginInitialState = {
  login: null,
  password: null,
  twoFa: null,
  isPending: false,
  error: null,
  requestID: null,
};

export const authLoginReducer = createReducer(
  authLoginInitialState,
  on(AuthLogin, (state, { login, password }) => ({
    ...state,
    login,
    password,
    isPending: true,
    error: null,
  })),
  on(AuthLoginSuccess, (state, { twoFa, requestID }) => ({
    ...state,
    isPending: false,
    twoFa,
    requestID,
  })),
  on(AuthLoginError, (state, { error }) => ({
    ...state,
    isPending: false,
    error,
  })),
  on(AuthLoginErrorReset, (state) => ({
    ...state,
    error: null,
  })),
  on(AuthLoginConfirmation, (state) => ({
    ...state,
    isPending: true,
    error: null,
  })),
  on(AuthLoginConfirmationError, (state, { error }) => ({
    ...state,
    isPending: false,
    error,
  })),
  on(AuthLoginReset, (_state) => ({
    ...authLoginInitialState,
  })),
);

export const authPageReducers = {
  [authLogin]: authLoginReducer,
};
