import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  AuthLogin,
  AuthLoginConfirmation,
  AuthLoginConfirmationError,
  AuthLoginConfirmationResend,
  AuthLoginConfirmationSuccess,
  AuthLoginError,
  AuthLoginSuccess,
} from './actions';
import { catchError, exhaustMap, map, of, switchMap, take, tap } from 'rxjs';
import { Router } from '@angular/router';
import { selectLogin, selectLoginRequestId } from './selectors';
import { AuthApiService } from '@common/services';

@Injectable()
export class AuthEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly api = inject(AuthApiService);
  private readonly router = inject(Router);

  public readonly login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthLogin, AuthLoginConfirmationResend),
      switchMap((action) =>
        action.type === AuthLoginConfirmationResend.type
          ? this.store.select(selectLogin).pipe(
              take(1),
              map(({ login, password }) => ({ login, password })),
            )
          : of({ login: action.login, password: action.password }),
      ),
      exhaustMap(({ login, password }: any) =>
        this.api.loginVerification({ login, password }).pipe(
          map(({ requestID }: any) =>
            AuthLoginSuccess({
              requestID,
              twoFa: {
                type: 'email',
              },
            }),
          ),
          tap(
            () =>
              void this.router.navigate(['/', 'auth', 'login', 'confirmation']),
          ),
          catchError((error) =>
            of(
              AuthLoginError({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public readonly loginConfirmation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthLoginConfirmation),
      exhaustMap(({ code }) =>
        this.store.select(selectLogin).pipe(
          take(1),
          switchMap(({ login, password, requestID }) =>
            this.api
              .loginVerificationConfirmation(code, requestID)
              .pipe(
                switchMap(() => this.api.login({ login, password, requestID })),
              ),
          ),
          tap(({ accessToken }) =>
            localStorage.setItem('accessToken', accessToken),
          ),
          map(() => AuthLoginConfirmationSuccess()),
          tap(() => {
            void this.router.navigate(['/', 'cabinet']);
          }),
          catchError((error) => of(AuthLoginConfirmationError({ error }))),
        ),
      ),
    ),
  );
}
